import { Slider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MonthSlug } from "../../models/Hero";
import styles from "./Player.module.sass";
import { usePlayerContext } from "./PlayerContextProvider";

const getMinutesAndSeconds = (value: number) => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.round(value - minutes * 60);

  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

interface PlayerProps {
  track: MonthSlug;
}
export const Player = ({ track }: PlayerProps) => {
  const { isPlaying, setPlaying } = usePlayerContext();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [playerValue, setPlayerValue] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  return (
    <>
      <audio
        style={{ display: "none" }}
        ref={audioRef}
        preload="metadata"
        controls
        onTimeUpdate={(e) => setPlayerValue(e.currentTarget.currentTime)}
        onLoadedMetadata={(e) => {
          setDuration(e.currentTarget.duration);
        }}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
      >
        <source src={`audios/${track.toLowerCase()}.mp3`} type="audio/mpeg" />
      </audio>
      <div className={styles.meta}>
        <span>{getMinutesAndSeconds(playerValue)}</span>
        <span>{getMinutesAndSeconds(duration) ?? "Загрузка..."}</span>
      </div>
      <Slider
        sx={{
          height: 8,
        }}
        min={0}
        max={Math.round(duration)}
        step={1}
        className={styles.slider}
        value={Math.round(playerValue)}
        onChange={(_, v) => {
          if (audioRef.current) {
            audioRef.current.currentTime = v as number;
          }
        }}
      />
    </>
  );
};
