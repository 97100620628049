import { createContext, PropsWithChildren, useContext, useState } from "react";

interface PlayerContextState {
  isPlaying: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PlayerContext = createContext<PlayerContextState | null>(null);

export const usePlayerContext = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw Error("usePlayerContext: context must be used within provider");
  }

  return context;
};

export const PlayerContextProvider = ({ children }: PropsWithChildren) => {
  const [isPlaying, setPlaying] = useState(false);

  return (
    <PlayerContext.Provider value={{ isPlaying, setPlaying }}>
      {children}
    </PlayerContext.Provider>
  );
};
