import { MonthSlug } from "../models/Hero";
import { Header } from "../ui/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import styles from "./MonthPage.module.sass";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";
import { MonthSlide } from "../ui/MonthSlide";
import { HeroContent } from "../ui/Hero";
import { usePlayerContext } from "../ui/Player/PlayerContextProvider";
import { heros } from "../resources/heros";

interface MonthPageProps {
  month: MonthSlug;
}
export const MonthPage = ({ month }: MonthPageProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(
    heros.findIndex(({ slug }) => slug === month) ?? 0
  );

  const [swiper, setSwiper] = useState<SwiperClass>();

  const navigate = useNavigate();

  const { setPlaying } = usePlayerContext();

  useEffect(() => {
    navigate("/" + heros[currentIndex].slug.toLowerCase());
  }, [currentIndex, navigate]);

  return (
    <>
      {swiper && (
        <Header month={month} swiper={swiper} currentIndex={currentIndex} />
      )}
      {heros.length > 0 && (
        <Swiper
          slidesPerView={1.4}
          centeredSlides={true}
          spaceBetween={20}
          onSwiper={setSwiper}
          initialSlide={currentIndex}
          className={styles.swipePage}
          onSlideChange={({ activeIndex }) => {
            setCurrentIndex(activeIndex);
            setPlaying(false);
          }}
        >
          {heros.map((hero) => (
            <SwiperSlide key={hero.slug} className={styles.slideWrapper}>
              {({ isActive }) => <MonthSlide isActive={isActive} hero={hero} />}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <HeroContent hero={heros[currentIndex]} />
    </>
  );
};
