import { ThemeProvider } from "@emotion/react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useParams,
  Navigate,
} from "react-router-dom";
import { Month } from "./models/Hero";
import { MonthPage } from "./pages/MonthPage";
import { PlayerContextProvider } from "./ui/Player/PlayerContextProvider";
import { muiTheme } from "./ui/muiTheme";
import styles from "./App.module.sass";

const PageParams = ({
  children,
}: {
  children: (res: Record<string, string>) => JSX.Element;
}) => children(useParams() as Record<string, string>);

const App = () => {
  if (
    !/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
      navigator.userAgent
    )
  ) {
    return (
      <p className={styles.noDesktopLabel}>
        Доступно только для мобильных устройств.
      </p>
    );
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <PlayerContextProvider>
        <Router>
          <Routes>
            <Route
              path="/:month"
              element={
                <PageParams>
                  {(params) => {
                    if (
                      Object.keys(Month)
                        .map((k) => k.toLowerCase())
                        .includes(params["month"])
                    ) {
                      return (
                        <MonthPage
                          month={
                            (params.month.slice(0, 1).toUpperCase() +
                              params.month.slice(1)) as any
                          }
                        />
                      );
                    }

                    return <>Not found</>;
                  }}
                </PageParams>
              }
            />
            <Route
              path="*"
              element={<Navigate replace to={"/january"} />}
            ></Route>
          </Routes>
        </Router>
      </PlayerContextProvider>
    </ThemeProvider>
  );
};

export default App;
