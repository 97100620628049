import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: "10px 0 !important",
        },
        track: {
          background: "linear-gradient(#FD5912, #FDAC4D)",
          border: "none",
        },
        thumb: {
          background: "#EA5616",
        },
        rail: {
          background: "#E0E0E0",
          border: "1px solid #e6e6e6",
          opacity: 1,
        },
      },
    },
  },
});
