import { Month, MonthSlug } from "../models/Hero";
import { Swiper } from "swiper/types";
import styles from "./Header.module.sass";
import chevronLeft from "../resources/icons/chevron-left.svg";

interface HeaderProps {
  month: MonthSlug;
  swiper: Swiper;
  currentIndex: number;
}
export const Header = ({ month, swiper, currentIndex }: HeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.chevronColumn}>
        <img
          src={chevronLeft}
          alt="Go left"
          onClick={() =>
            swiper.slideTo(currentIndex === 0 ? 11 : currentIndex - 1)
          }
        />
      </div>
      <div className={styles.titleColumn}>
        <h1>{Month[month]}</h1>
      </div>
      <div className={styles.chevronColumn}>
        <img
          src={chevronLeft}
          style={{ transform: "rotate(180deg)" }}
          alt="Go right"
          onClick={() =>
            swiper.slideTo(currentIndex === 11 ? 0 : currentIndex + 1)
          }
        />
      </div>
    </div>
  );
};
