import { Hero } from "../models/Hero";
import logo from "../resources/icons/logo.svg";
import play from "../resources/icons/play.svg";
import pause from "../resources/icons/pause.svg";
import styles from "./MonthSlide.module.sass";
import { usePlayerContext } from "./Player/PlayerContextProvider";

interface MonthSlideProps {
  isActive: boolean;
  hero: Hero;
}
export const MonthSlide = ({ isActive, hero }: MonthSlideProps) => {
  const { isPlaying, setPlaying } = usePlayerContext();

  return (
    <div
      className={styles.slideInner + ` ${isActive ? styles.active : ""}`}
      style={{
        backgroundImage: `url('images/${hero.slug.toLowerCase()}.png')`,
      }}
    >
      <div className={styles.slideBackdrop}>
        <div className={styles.slideContent}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.slideTitle}>{hero.name}</div>
          <div className={styles.playButton}>
            <img
              onClick={() => setPlaying((prev) => !prev)}
              src={isPlaying ? pause : play}
              alt={isPlaying ? "pause" : "play"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
