import { Hero } from "../models/Hero";
import styles from "./Hero.module.sass";
import { Player } from "./Player/Player";

interface HeroContentProps {
  hero: Hero;
}
export const HeroContent = ({ hero }: HeroContentProps) => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.contentInner}>
        <Player key={hero.slug} track={hero.slug} />
        <div className={styles.textWrapper}>
          {hero.description.map((p, i) => (
            <p className={styles.description} key={i}>
              {p}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
