export interface Hero {
  name: string;
  slug: MonthSlug;
  description: string[];
}

export enum Month {
  January = "Январь",
  February = "Февраль",
  March = "Март",
  April = "Апрель",
  May = "Май",
  June = "Июнь",
  July = "Июль",
  August = "Август",
  September = "Сентябрь",
  October = "Октябрь",
  November = "Ноябрь",
  December = "Декабрь",
}

export type MonthSlug = keyof typeof Month;
